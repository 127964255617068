import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../assets/LOGO.png";
import sara from "../../assets/sara.jpg";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import AnimationIcon from "@mui/icons-material/Animation";

const Header = (propse) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    if (e === "Add Flash") {
      propse.setExtra(true);
      propse.setExtraKind("Add Flash");
    }
    if (e === "Review Flashes") {
      propse.setExtra(true);
      propse.setExtraKind("Review Flashes");
    }
    if (e === "Start Game") {
      propse.setExtra(true);
      propse.setExtraKind("Start Game");
    }
    if (e === "Final Flash") {
      propse.setExtra(true);
      propse.setExtraKind("Final Flash");
    }
    if (e === "See Profile") {
      propse.setExtra(true);
      propse.setExtraKind("See Profile");
    }
    setAnchorEl(null);
  };
  const options = [
    "See Profile",
    "Add Flash",
    "Review Flashes",
    "Start Game",
    "Final Flash",
  ];
  const ITEM_HEIGHT = 48;
  const onClick = (e) => {
    if (e === "ADD") {
      propse.setAdd(true);
      propse.setReview(false);
      propse.setGame(false);
      propse.setFinal(false);
      propse.setProfile(false);
    }
    if (e === "REVIEW") {
      propse.setAdd(false);
      propse.setReview(true);
      propse.setGame(false);
      propse.setFinal(false);
      propse.setProfile(false);
    }
    if (e === "GAME") {
      propse.setAdd(false);
      propse.setReview(false);
      propse.setGame(true);
      propse.setFinal(false);
      propse.setProfile(false);
    }
    if (e === "FINAL") {
      propse.setAdd(false);
      propse.setReview(false);
      propse.setGame(false);
      propse.setFinal(true);
      propse.setProfile(false);
    }
    if (e === "AVATAR") {
      propse.setAdd(false);
      propse.setReview(false);
      propse.setGame(false);
      propse.setFinal(false);
      propse.setProfile(true);
    }
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          minHeight: "70px",
          display: "flex",
          flexDirection: "row",
          //backgroundColor: "rgba(200,200,200,0.1)",
          fontFamily: "Aboreto",
        }}
      >
        <Box
          sx={{
            width: "50px",
            height: "70px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={Logo} alt="" width={50} height={50} />
        </Box>
        <Box
          sx={{
            width: propse.Width < 560 ? "calc(80% - 50px)" : "calc(50% - 50px)",
            height: "70px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontWeight: "bolder",
          }}
        >
          <span style={{ marginLeft: propse.Width < 560 ? "10px" : "15px" }}>
            LEITNER BOX
          </span>
        </Box>
        <Box
          sx={{
            width: propse.Width < 560 ? "20%" : "50%",
            height: "70px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            fontSize: "0.8rem",
          }}
        >
          <Box
            className={propse.Add ? "AUL2" : "AUL"}
            sx={{
              marginRight: "15px",
              cursor: "pointer",
              display: propse.Width < 560 ? "none" : "flex",
            }}
            onClick={() => onClick("ADD")}
          >
            add
          </Box>
          <Box
            className={propse.Review ? "AUL2" : "AUL"}
            sx={{
              marginRight: "15px",
              cursor: "pointer",
              display: propse.Width < 560 ? "none" : "flex",
            }}
            onClick={() => onClick("REVIEW")}
          >
            review
          </Box>
          <Box
            className={propse.Game ? "AUL2" : "AUL"}
            sx={{
              marginRight: "15px",
              cursor: "pointer",
              display: propse.Width < 560 ? "none" : "flex",
            }}
            onClick={() => onClick("GAME")}
          >
            game
          </Box>
          <Box
            className={propse.Final ? "AUL2" : "AUL"}
            sx={{
              marginRight: "15px",
              cursor: "pointer",
              display: propse.Width < 560 ? "none" : "flex",
            }}
            onClick={() => onClick("FINAL")}
          >
            final
          </Box>
          <Avatar
            sx={{
              width: "45px",
              height: "45px",
              cursor: "pointer",
              display: propse.Width < 560 ? "none" : "flex",
            }}
            alt=""
            src={sara}
            size="small"
            onClick={() => onClick("AVATAR")}
          />
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{
              display: propse.Width < 560 ? "flex" : "none",
              marginRight: "8px",
            }}
          >
            <ListRoundedIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 6.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pyxis"}
                onClick={() => handleClose(option)}
                sx={{
                  height: "50px",
                  fontFamily: "Poppins",
                  fontSize: "0.83rem",
                  transition: "0.3s",
                  color: "#707070",
                  marginTop:
                    option === "Add Flash" || option === "See Profile"
                      ? "10px"
                      : null,
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor =
                    option !== "See Profile" ? "rgba(255,195,0,0.2)" : "#FFF")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor =
                    option !== "See Profile" ? "#FFF" : "#FFF")
                }
              >
                {option === "Add Flash" ? (
                  <AttachFileIcon
                    sx={{
                      fontSize: "1.3rem",
                      marginRight: "8px",
                    }}
                  />
                ) : option === "Review Flashes" ? (
                  <RemoveRedEyeIcon
                    sx={{
                      fontSize: "1.3rem",
                      marginRight: "8px",
                    }}
                  />
                ) : option === "Start Game" ? (
                  <SportsBasketballIcon
                    sx={{
                      fontSize: "1.3rem",
                      marginRight: "8px",
                    }}
                  />
                ) : option === "Final Flash" ? (
                  <AnimationIcon
                    sx={{
                      fontSize: "1.3rem",
                      paddingRight: "18px",
                    }}
                  />
                ) : option === "See Profile" ? (
                  <Avatar
                    sx={{
                      width: "45px",
                      height: "45px",
                      cursor: "pointer",
                    }}
                    alt=""
                    src={sara}
                    size="small"
                  />
                ) : null}
                {option === "See Profile" ? (
                  <span style={{ marginLeft: "10px" }}>{option}</span>
                ) : (
                  option
                )}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Header;
