import { Box } from "@mui/material";
import React from "react";

const Seprate = (propse) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "2px",
          backgroundColor: propse.Color1,
          borderRadius: "3px",
          boxShadow: `0rem 0rem 0.1rem  ${propse.Color2}`,
        }}
      ></Box>
      <Box
        sx={{
          width: "16px",
          height: "2px",
          backgroundColor: propse.Color1,
          borderRadius: "3px",
          marginLeft: "3px",
          boxShadow: `0rem 0rem 0.1rem  ${propse.Color2}`,
        }}
      ></Box>
      <Box
        sx={{
          width: "8px",
          height: "2px",
          backgroundColor: propse.Color1,
          borderRadius: "3px",
          marginLeft: "3px",
          boxShadow: `0rem 0rem 0.1rem  ${propse.Color2}`,
        }}
      ></Box>
    </Box>
  );
};

export default Seprate;
