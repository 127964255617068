import { Box } from "@mui/material";
import React from "react";
import APC from "./APC";
import APR from "./APR";
import APL from "./APL";

const AddPage = (propse) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 50px)",
          marginTop: propse.Width < 560 ? "0" : "25px",
          display: "flex",
          flexDirection:
            propse.Width < 560 || (propse.Width >= 560 && propse.Width < 850)
              ? "column"
              : "row",
          fontFamily: "Poppins",
          overflowY: propse.Width < 560 ? "auto" : null,
        }}
      >
        <APL Width={propse.Width} Height={propse.Height} />
        <APC Width={propse.Width} Height={propse.Height} />
        <APR Width={propse.Width} Height={propse.Height} />
      </Box>
    </React.Fragment>
  );
};

export default AddPage;
