import { Box } from "@mui/material";
import React from "react";
import SocialMedia from "../entrance/SocialMedia";

const Footer = (propse) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          minHeight: "70px",
          fontFamily: "Outfit",
          fontSize: "0.7rem",
          color: "rgba(0,0,0,0.6)",
          //backgroundColor: "rgba(200,200,200,0.3)",
        }}
      >
        <Box
          sx={{
            width: propse.Width < 560 ? "100%" : "50%",
            marginTop: "0px",
            marginLeft:
              propse.Width >= 560 && propse.Width < 768 ? "00px" : "00px",
          }}
        >
          <span>Copyright © December-2021. All rights Reserved.</span>
          <span>Created by Ramin Maghsoodi</span>
          <SocialMedia />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
