import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyC9rDnQ9Dz6yKMzPNmKN5XOw3VI4V0s3KU",
  authDomain: "lightnerbox.firebaseapp.com",
  databaseURL: "https://lightnerbox-default-rtdb.firebaseio.com",
  projectId: "lightnerbox",
  storageBucket: "lightnerbox.appspot.com",
  messagingSenderId: "814016448210",
  appId: "1:814016448210:web:0ae031259581c7e7a8cf0b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
