import { Box, IconButton } from "@mui/material";
import React from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import AddPage from "../pages/addpage/AddPage";
import ReviewPage from "../pages/reviewpage/ReviewPage";
import GamePage from "../pages/gamepage/GamePage";
import FinalPage from "../pages/finalpage/FinalPage";
import Profile from "../pages/profile/Profile";

const ExtraPage = (propse) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          backgroundColor: "#FFF",
          marginLeft: "100%",
          zIndex: "10",
          animation:
            propse.Width < 560
              ? propse.Extra === true
                ? "RTL 0.3s ease-in-out 0s 1 forwards"
                : propse.Extra === false
                ? "LTR 0.3s ease-in-out 0s 1 backwards"
                : null
              : null,
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "90%",
            marginLeft: "10%",
            marginTop: "5%",
          }}
        >
          <Box sx={{ width: "100%", height: "60px" }}>
            <IconButton
              sx={{ width: "40px", height: "40px", marginLeft: "-14px" }}
              onClick={() => propse.setExtra(false)}
            >
              <ArrowBackIosRoundedIcon
                sx={{ cursor: "pointer", color: "#707070" }}
              />
            </IconButton>
          </Box>
          {propse.ExtraKind === "Add Flash" ? (
            <AddPage Width={propse.Width} Height={propse.Height} />
          ) : propse.ExtraKind === "Review Flashes" ? (
            <ReviewPage Width={propse.Width} Height={propse.Height} />
          ) : propse.ExtraKind === "Start Game" ? (
            <GamePage Width={propse.Width} Height={propse.Height} />
          ) : propse.ExtraKind === "Final Flash" ? (
            <FinalPage Width={propse.Width} Height={propse.Height} />
          ) : propse.ExtraKind === "See Profile" ? (
            <Profile Width={propse.Width} Height={propse.Height} />
          ) : null}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ExtraPage;
