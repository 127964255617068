import { Box } from "@mui/material";
import React from "react";
import Seprate from "../Seprate";

const APL = (propse) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width:
            propse.Width < 560 || (propse.Width >= 560 && propse.Width < 850)
              ? "95%"
              : "30%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          //backgroundColor: "rgba(100,100,100,0.1)",
        }}
      >
        <Box
          sx={{
            color: "#00a78e",
            fontFamily: "Poppins",
            fontSize: "0.8rem",
            fontWeight: "bold",
          }}
        >
          Add
        </Box>
        <Box
          sx={{
            fontFamily: "Poppins",
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "rgba(70,70,70)",
          }}
        >
          Add Flashcards
        </Box>
        <Seprate Color1="rgba(0,167,142)" Color2="rgba(0,167,142,0.5)" />
        <Box
          sx={{
            marginTop: "15px",
            color: "rgba(100,100,100)",
            fontFamily: "Poppins",
            fontSize: "0.8rem",
            textAlign: "justify",
          }}
        >
          n this method, flashcards are sorted into groups according to how well
          the learner knows each one in Leitner's learning box. The learners try
          to recall the solution written on a flashcard. If they succeed, they
          send the card to the next group. If they fail, they send it back to
          the first group. Each succeeding group has a longer.
        </Box>
        <Box
          sx={{
            marginTop: "40px",
            color: "rgba(70,70,70)",
            fontFamily: "Poppins",
            fontSize: "0.8rem",
          }}
        >
          <Box sx={{ cursor: "pointer" }}>
            Select Categories
            <i
              className="fa fa-arrow-right"
              aria-hidden="true"
              style={{ marginLeft: "8px", color: "rgba(0,167,0)" }}
            ></i>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default APL;
