import { Box } from "@mui/material";
import React from "react";
import COFFEE from "../../../assets/coffee.png";

const APR = (propse) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: propse.Width >= 560 && propse.Width < 850 ? "100%" : "30%",
          minHeight: "100%",
          display: propse.Width < 560 ? "none" : "flex",
          //backgroundColor: "rgba(100,100,100,0.3)",
          flexDirection:
            propse.Width >= 560 && propse.Width < 850 ? "column" : null,
          alignItems:
            propse.Width >= 560 && propse.Width < 850 ? "center" : null,
          marginBottom:
            propse.Width >= 560 && propse.Width < 850 ? "50px" : null,
          marginTop: propse.Width >= 560 && propse.Width < 850 ? "25px" : null,
        }}
      >
        <img
          src={COFFEE}
          alt=""
          width={propse.Width >= 850 && propse.Width < 1250 ? 220 : 300}
          height={propse.Width >= 850 && propse.Width < 1250 ? 264 : 361}
        />
      </Box>
    </React.Fragment>
  );
};

export default APR;
