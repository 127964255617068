import { Box } from "@mui/material";
import React from "react";

const ReviewPage = (propse) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 50px)",
          marginTop: propse.Width < 560 ? "0" : "25px",
          display: "flex",
          flexDirection: "row",
          //backgroundColor: "rgba(100,100,100,0.1)",
        }}
      >
        <Box>1</Box>
        <Box>2</Box>
        <Box>3</Box>
      </Box>
    </React.Fragment>
  );
};

export default ReviewPage;
