import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Bodi from "./Bodi";
import Footer from "./Footer";
import UiDimensions from "../../UI/ui-dimensions/UiDimensions";
import "../../UI/ui-animation/AUL.css";
import "../../UI/ui-animation/AUL2.css";
import "../../UI/ui-key/UiKey.css";
import ExtraPage from "./ExtraPage";

const Game = () => {
  const Width = UiDimensions().innerWidth;
  const Height = UiDimensions().innerHeight;
  const [Extra, setExtra] = useState(null);
  const [Add, setAdd] = useState(false);
  const [Review, setReview] = useState(false);
  const [Game, setGame] = useState(false);
  const [Final, setFinal] = useState(false);
  const [Profile, setProfile] = useState(false);
  const [ExtraKind, setExtraKind] = useState("");
  useEffect(() => {
    if (Width < 560) setExtra(null);
  }, [Width]);
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100vh",
        }}
      >
        <ExtraPage
          Width={Width}
          Height={Height}
          Extra={Extra}
          setExtra={setExtra}
          ExtraKind={ExtraKind}
        />
        <Box
          sx={{
            width: "80%",
            height: "90vh",
            marginLeft: "10%",
            marginTop: "5vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header
            Width={Width}
            Height={Height}
            Extra={Extra}
            setExtra={setExtra}
            Add={Add}
            setAdd={setAdd}
            Review={Review}
            setReview={setReview}
            Game={Game}
            setGame={setGame}
            Final={Final}
            setFinal={setFinal}
            Profile={Profile}
            setProfile={setProfile}
            setExtraKind={setExtraKind}
          />
          <Bodi
            Width={Width}
            Height={Height}
            Add={Add}
            setAdd={setAdd}
            Review={Review}
            setReview={setReview}
            Game={Game}
            setGame={setGame}
            Final={Final}
            setFinal={setFinal}
            Profile={Profile}
            setProfile={setProfile}
          />
          <Footer Width={Width} Height={Height} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Game;
