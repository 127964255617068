import { Box, IconButton } from "@mui/material";
import React from "react";

const SocialMedia = () => {
  return (
    <Box sx={{ width: "100%", height: "40px", marginLeft: "5px" }}>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{
          marginRight: "6px",
        }}
      >
        <i
          className="fa fa-instagram "
          style={{
            fontSize: "1rem",
            color: "#3f4450",
            transition: "color 0.1s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#ed013e")}
          onMouseLeave={(e) => (e.target.style.color = "#3f4450")}
        ></i>
      </IconButton>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{ marginRight: "6px" }}
      >
        <i
          className="fa fa-dribbble "
          style={{
            fontSize: "1rem",
            color: "#ed013e",
            transition: "color 0.1s ease-in-out",
          }}
        ></i>
      </IconButton>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{ marginRight: "6px" }}
      >
        <i
          className="fa fa-linkedin "
          style={{
            fontSize: "1rem",
            color: "#3f4450",
            transition: "color 0.1s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#ed013e")}
          onMouseLeave={(e) => (e.target.style.color = "#3f4450")}
        ></i>
      </IconButton>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{ marginRight: "6px" }}
      >
        <i
          className="fa fa-youtube"
          style={{
            fontSize: "0.85rem",
            marginTop: "-1px",
            transition: "color 0.1s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#ed013e")}
          onMouseLeave={(e) => (e.target.style.color = "#3f4450")}
        ></i>
      </IconButton>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{ marginRight: "6px" }}
      >
        <i
          className="fa fa-pinterest "
          style={{
            fontSize: "1rem",
            color: "#3f4450",
            transition: "color 0.1s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#ed013e")}
          onMouseLeave={(e) => (e.target.style.color = "#3f4450")}
        ></i>
      </IconButton>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{ marginRight: "6px" }}
      >
        <i
          className="fa fa-twitter "
          style={{
            fontSize: "1rem",
            color: "#3f4450",
            transition: "color 0.1s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#ed013e")}
          onMouseLeave={(e) => (e.target.style.color = "#3f4450")}
        ></i>
      </IconButton>
      <IconButton
        size="medume"
        edge="start"
        aria-label="menu"
        sx={{ marginRight: "-15px" }}
      >
        <i
          className="fa fa-gitlab "
          style={{
            fontSize: "1rem",
            color: "#3f4450",
            transition: "color 0.1s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#ed013e")}
          onMouseLeave={(e) => (e.target.style.color = "#3f4450")}
        ></i>
      </IconButton>
    </Box>
  );
};

export default SocialMedia;
