import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import "../../../../src/font-awesome-4.7.0/css/font-awesome.min.css";

const APC = (propse) => {
  const [Front, setFront] = useState("");
  const [Back, setBack] = useState("");
  const [Next, setNext] = useState(false);
  const [Error1, setError1] = useState(false);
  const [Error2, setError2] = useState(false);
  const onChangeFront = (e) => {
    setFront(e.target.value);
    setError1(false);
  };
  const onChangeBack = (e) => {
    setBack(e.target.value);
    setError2(false);
  };
  const onClick = () => {
    setNext(false);
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    if (Back.length === 0) {
      setError2(true);
    }
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          width:
            propse.Width < 560 || (propse.Width >= 560 && propse.Width < 850)
              ? "95%"
              : "40%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "rgba(100,100,100,0.1)",
          marginTop:
            propse.Width < 560
              ? "-50px"
              : propse.Width >= 560 && propse.Width < 850
              ? "-125px"
              : null,
        }}
      >
        <Box
          sx={{
            width:
              propse.Width < 560 || (propse.Width >= 560 && propse.Width < 850)
                ? "100%"
                : "calc(100% - 150px)",
            height: "calc(100% - 40px)",
            //backgroundColor: "rgba(100,100,100,0.2)",
            marginLeft:
              propse.Width < 560 || (propse.Width >= 560 && propse.Width < 850)
                ? "0"
                : "75px",
            marginTop: "20px",
            fontFamily: "Poppins",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              position: "relative",
              color: "rgba(50,50,50)",
              fontSize: "0.8rem",
              fontWeight: "bolder",
            }}
          >
            Flashcard
          </span>
          <span
            style={{
              fontFamily: "Outfit",
              color: "rgba(0,167,142)",
              fontSize: "1.5rem",
              marginTop: "10px",
              fontWeight: "bolder",
            }}
          >
            153
            <sup
              style={{
                position: "relative",
                fontSize: "0.65rem",
                letterSpacing: "0.1rem",
                top: "-5px",
                marginLeft: "3px",
              }}
            >
              FC
            </sup>
          </span>
          <Box
            sx={{
              fontSize: "0.7rem",
              color: "rgba(100,100,100)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span>{!Next ? "write front FC" : "write back FC"}</span>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <i
                className="fa fa-chevron-left"
                aria-hidden="true"
                style={{
                  marginTop: "-1px",
                  marginRight: "15px",
                  cursor: "pointer",
                  color: "rgba(100,100,100)",
                  display: Next ? "flex" : "none",
                }}
                onClick={onClick}
              ></i>
              <i
                className="fa fa-trash-o"
                aria-hidden="true"
                style={{
                  fontSize: "0.9rem",
                  marginTop: "-3px",
                  marginRight: "5px",
                  cursor: "pointer",
                  color: "rgba(100,100,100)",
                  display:
                    Front.length !== 0 ? (Next ? "none" : "flex") : "none",
                }}
                onClick={() => setFront("")}
              ></i>
              <i
                className="fa fa-trash-o"
                aria-hidden="true"
                style={{
                  fontSize: "0.9rem",
                  marginTop: "-3px",
                  marginRight: "5px",
                  cursor: "pointer",
                  color: "rgba(100,100,100)",
                  display:
                    Back.length !== 0 ? (Next ? "flex" : "none") : "none",
                }}
                onClick={() => setBack("")}
              ></i>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "rgba(0,0,0,0.1)",
              marginTop: "10px",
            }}
          ></Box>
          <form onSubmit={onSubmit}>
            <textarea
              id="w3reviewFront"
              name="w3reviewFront"
              rows="7"
              cols="50"
              style={{
                marginTop: "10px",
                width: "100%",
                border: "none",
                backgroundColor: propse.Width >= 560 ? "#edefec" : null,
                fontFamily: "Poppins",
                fontSize: "0.8rem",
                color: "rgba(100,100,100)",
                resize: "none",
                outline: "none",
                display: Next ? "none" : "flex",
              }}
              placeholder="Here to write !"
              onChange={onChangeFront}
              value={Front}
            ></textarea>
            <textarea
              id="w3reviewBack"
              name="w3reviewBack"
              rows="7"
              cols="50"
              style={{
                marginTop: "10px",
                width: "100%",
                border: "none",
                backgroundColor: propse.Width >= 560 ? "#edefec" : null,
                fontFamily: "Poppins",
                fontSize: "0.8rem",
                color: "rgba(100,100,100)",
                resize: "none",
                outline: "none",
                display: Next ? "flex" : "none",
              }}
              placeholder="Here to write !"
              onChange={onChangeBack}
              value={Back}
            ></textarea>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  display: Next ? "none" : "flex",
                  backgroundColor: "rgba(0,167,142)",
                  width: propse.Width < 560 ? "100%" : "100px",
                  height: propse.Width < 560 ? "45px" : "38px",
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  zIndex: "6",
                  borderRadius: propse.Width < 560 ? null : "20px",
                  marginBottom: propse.Width < 560 ? "50px" : "0",
                  marginTop: propse.Width < 560 ? "30px" : "0",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "rgba(0,167,142)")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "rgba(0,167,142)")
                }
                onClick={() =>
                  Front.length !== 0 ? setNext(true) : setError1(true)
                }
              >
                NEXT
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  display: Next ? "flex" : "none",
                  backgroundColor: "rgba(0,167,142)",
                  width: propse.Width < 560 ? "100%" : "100px",
                  height: propse.Width < 560 ? "45px" : "38px",
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  zIndex: "6",
                  borderRadius: propse.Width < 560 ? null : "20px",
                  marginBottom: propse.Width < 560 ? "50px" : "0",
                  marginTop: propse.Width < 560 ? "30px" : "0",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "rgba(0,167,142)")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "rgba(0,167,142)")
                }
              >
                ADD
              </Button>
              <Box
                sx={{
                  width: propse.Width < 560 ? "100%" : "150px",
                  height: propse.Width < 560 ? "45px" : "38px",
                  display: Error1 || Error2 ? "flex" : "none",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "0.65rem",
                  marginLeft: "10px",
                  color: "#ed013e",
                }}
              >
                {Error1
                  ? " Type your Front Flashcard"
                  : " Type your Back Flashcard"}
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default APC;
