import { Box, Button } from "@mui/material";
import React from "react";
import orang from "../../assets/orange.png";
import AddPage from "../pages/addpage/AddPage";
import ReviewPage from "../pages/reviewpage/ReviewPage";
import GamePage from "../pages/gamepage/GamePage";
import FinalPage from "../pages/finalpage/FinalPage";
import Profile from "../pages/profile/Profile";

const Bodi = (propse) => {
  const onClick = () => {
    propse.setAdd(false);
    propse.setReview(false);
    propse.setGame(true);
    propse.setFinal(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          minHeight: propse.Width < 560 ? "calc(100% - 140px)" : "417px",
          display: "flex",
          flexDirection: "row",
          overflowY: "auto",
          //backgroundColor: "rgba(200,200,200,0.2)",
        }}
      >
        {propse.Add ? (
          <AddPage Width={propse.Width} Height={propse.Height} />
        ) : propse.Review ? (
          <ReviewPage Width={propse.Width} Height={propse.Height} />
        ) : propse.Game ? (
          <GamePage Width={propse.Width} Height={propse.Height} />
        ) : propse.Final ? (
          <FinalPage Width={propse.Width} Height={propse.Height} />
        ) : propse.Profile ? (
          <Profile />
        ) : (
          <React.Fragment>
            <Box
              sx={{
                width: propse.Width < 560 ? "100%" : "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontFamily: "Aboreto",
                fontSize:
                  propse.Width >= 560 && propse.Width < 930
                    ? "1.5rem"
                    : propse.Width < 560
                    ? "1.8rem"
                    : "2.5rem",
                fontWeight: "bolder",
                //backgroundColor: "rgba(200,200,200,0.2)",
              }}
            >
              <Box
                sx={{
                  marginLeft: "0px",
                  width: "80px",
                  height: "8px",
                  backgroundColor: "#ed013e",
                }}
              ></Box>
              <Box sx={{ marginLeft: "0px", marginTop: "10px" }}>
                BUILDING A
              </Box>
              <Box sx={{ marginLeft: "0px" }}>PERSONAL-DREAM</Box>
              <Box sx={{ fontFamily: "Aboreto" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#ed013e",
                    width: "150px",
                    height: "45px",
                    fontFamily: "Poppins",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    zIndex: "6",
                    marginTop: "30px",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#ed013e")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#ed013e")
                  }
                  onClick={onClick}
                >
                  start
                  <i
                    className="fa fa-long-arrow-right"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                height: "100%",
                display: propse.Width < 560 ? "none" : "flex",
                flexDirection: "column",
                justifyContent: "center",
                //backgroundColor: "rgba(200,200,200,0.5)",
              }}
            >
              <img
                src={orang}
                alt=""
                width={
                  propse.Width >= 560 && propse.Width < 700
                    ? 220
                    : propse.Width >= 700 && propse.Width < 900
                    ? 280
                    : propse.Width >= 900 && propse.Width < 1100
                    ? 350
                    : 400
                }
                height="auto"
                style={{
                  marginLeft: propse.Width >= 1130 ? "50px" : "0px",
                }}
              />
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Bodi;
